import React from "react";
import { useInView } from "react-intersection-observer";

const NumbersSection = () => {
  // Hooks for each card
  const { ref: card1Ref, inView: card1InView } = useInView({ threshold: 0.1 });
  const { ref: card2Ref, inView: card2InView } = useInView({ threshold: 0.1 });
  const { ref: card3Ref, inView: card3InView } = useInView({ threshold: 0.1 });

  return (
    <div className="flex flex-col items-center bg-white py-28">
      {/* Title */}
      <h2 className="text-3xl md:text-4xl text-center font-sans font-bold pb-20">
        إنجـــــــازات <span className="text-indigo-500 "> مُحاسِب سند الذكى</span>
      </h2>

      {/* Cards Container */}
      <div className="flex flex-wrap justify-center gap-20 text-center">
        
        {/* Card 3 (Reordered to be First) */}
        <div
          ref={card3Ref}
          className={`flex flex-col items-center md:order-2 transform transition-all duration-700 ${
            card3InView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
          }`}
        >
          <img
            src="/hand.png"
            alt="hand"
            className="text-indigo-600 mb-3"
            style={{ width: '50px', height: '50px' }}
          />
          <p className="text-gray-800 font-bold mb-4 font-sans">فرع تم خدمته</p>
          <p className="text-indigo-500 font-bold text-2xl">+70</p>
        </div>

        {/* Card 1 */}
        <div
          ref={card1Ref}
          className={`flex flex-col items-center md:order-3 transform transition-all duration-700 ${
            card1InView ? "translate-x-0 opacity-100" : "-translate-x-10 opacity-0"
          }`}
        >
          <img
            src="/trademark1.png"
            alt="trademark"
            className="text-indigo-600 mb-3"
            style={{ width: '50px', height: '50px' }}
          />
          <p className="text-gray-800 font-bold mb-4 font-sans">علامه تجاريه</p>
          <p className="text-indigo-500 font-bold text-2xl">+50</p>
        </div>

        {/* Card 2 */}
        <div
          ref={card2Ref}
          className={`flex flex-col items-center md:order-1 transform transition-all duration-700 ${
            card2InView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
          }`}
        >
          <img
            src="/money-management.png"
            alt="money-management"
            className="text-indigo-600 mb-3"
            style={{ width: '50px', height: '50px' }}
          />
          <p className="text-gray-800 font-bold mb-4 font-sans">مصروفات تمت إدارتها</p>
          <p className="text-indigo-500 font-bold text-2xl">+7M</p>
        </div>
      </div>
    </div>
  );
};

export default NumbersSection;
