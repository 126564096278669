// src/App.js
import React from 'react';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './Routes/route';  // Import the RoutesComponent

function App() {
  return (
    <HelmetProvider>
      <Router>
        <RoutesComponent /> {/* Use the RoutesComponent to manage routes */}
      </Router>
    </HelmetProvider>
  );
}

export default App;
