import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import WaveDivider from '../components/WaveDivider';
import FeaturesSection from '../components/FeaturesSection';
import CompanyHeader from '../components/CompanyHeader';
import MenuCost from '../components/MenuCost';
import PricingPlans from '../components/PricingPlans';
import HowItWorks from '../components/HowItWorks';
import JobHeader from '../components/JobHeader';
import ClientLogos from '../components/ClientLogos';
import FAQ from '../components/Faq';
import Jobs from '../components/Jobs';
import PrivacyPolicy from '../components/PrivacyPolicy';
import PromoSection from '../Pages/PromoSection';
import LogoBar from '../Pages/LogoBar';
import ProfessionalPackage from '../Pages/ProfessionalPackage';
import NumbersSection from '../Pages/NumbersSection';
import FeatureSection from '../Pages/FeatureSection';
import MenuComparison from '../Pages/MenuComparison';
import PricePlans from '../Pages/PricePlans';
import FooterLanding from '../Pages/FooterLanding';
import { Helmet } from 'react-helmet-async';

const RoutesComponent = () => {
  return (
    <Routes>
      {/* Main Route */}
      <Route
        path="/"
        element={
          <Layout>
            <WaveDivider />
            <FeaturesSection />
            <CompanyHeader />
            <MenuCost />
            <PricingPlans />
            <HowItWorks />
            <JobHeader />
            <ClientLogos />
            <FAQ />
            <Helmet>
              <title>Sanad App</title>
              <meta
                name="description"
                content="Welcome to سند الذكي, your trusted smart accounting solution. Explore our features, pricing plans, and how we can help your business grow."
              />
              <meta name="keywords" content="Smart Accounting, سند الذكي, Features, Pricing, Clients, FAQ" />
            </Helmet>
          </Layout>
        }
      />
      {/* Main (الرئيسيه) Route */}
      <Route
        path="/main"
        element={
          <Layout>
            <WaveDivider />
            <FeaturesSection />
            <CompanyHeader />
            <MenuCost />
            <PricingPlans />
            <HowItWorks />
            <JobHeader />
            <ClientLogos />
            <FAQ />
            <Helmet>
              <title>الرئيسيه</title>
              <meta
                name="description"
                content="Welcome to سند الذكي, your trusted smart accounting solution. Explore our features, pricing plans, and how we can help your business grow."
              />
              <meta name="keywords" content="Smart Accounting, سند الذكي, Features, Pricing, Clients, FAQ" />
            </Helmet>
          </Layout>
        }
      />
      {/* Additional Routes */}
      <Route path="/packages" element={<Layout><PricingPlans /></Layout>} />
      <Route path="/works" element={<Layout><HowItWorks /></Layout>} />
      <Route path="/whyUs" element={<Layout><FeaturesSection /></Layout>} />
      <Route path="/jobs" element={<Layout><Jobs /></Layout>} />
      <Route path="/clients" element={<Layout><ClientLogos /></Layout>} />
      <Route path="/faq" element={<Layout><FAQ /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
      {/* Landing Page */}
      <Route
        path="/landing"
        element={
          <Layout>
            <PromoSection />
            <LogoBar />
            <ProfessionalPackage />
            <NumbersSection />
            <FeatureSection />
            <MenuComparison />
            <PricePlans />
            <FooterLanding />
            <Helmet>
              <title>سحابه سند</title>
              <meta
                name="description"
                content="Welcome to سند الذكي, your trusted smart accounting solution. Explore our features, pricing plans, and how we can help your business grow."
              />
              <meta name="keywords" content="Smart Accounting, سند الذكي, Features, Pricing, Clients, FAQ" />
            </Helmet>
          </Layout>
        }
      />
    </Routes>
  );
};

export default RoutesComponent;
