import React from 'react';
import '../index.css';

function LogoBar() {
  return (
    <div className="overflow-hidden   mx-auto relative h-20 flex items-center py-14">
      <div className="marquee-track">
        {/* First set of images */}
        <img src="/korkom.jpg" alt="Logo 6" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
         <img src="/trust.jpeg" alt="Logo 4" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/slow.jpg" alt="Logo 5" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/shinkar.jpeg" alt="Logo 1" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/view.png" alt="Logo 3" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/hexa.jpeg" alt="Logo 3" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/Bave.png" alt="Bave" className="border border-[indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/إلتزام العقارية.png" alt="إلتزام العقارية" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/مقهي البومة.png" alt="مقهي البومة" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/بيت البرجر.png" alt="بيت البرجر" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/Cob bakery.png" alt="Cob baker" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/serdar.png" alt="serdar" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/مطعم دايترز لتقديم الوجبات.png" alt="مطعم دايترز لتقديم الوجبات" className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
        <img src="/مستكا .jpg" alt="مستكا " className="border border-indigo-500 w-20 h-20 rounded-full object-cover" />
      </div>
    </div>
  );
}

export default LogoBar;
