import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const PromoSection = () => {
  const [loaded, setLoaded] = useState(false);

  // Hooks for individual elements
  const { ref: logoRef, inView: logoInView } = useInView();
  const { ref: textRef, inView: textInView } = useInView();
  const { ref: buttonRef, inView: buttonInView } = useInView();
  const { ref: imageRef, inView: imageInView } = useInView();

  return (
    <section className="relative overflow-x-hidden flex flex-col md:flex-row items-stretch bg-indigo-500 text-white ">
      {/* Right Side: Logo, Heading, and Button */}
      <div className="w-full md:w-1/2 flex flex-col justify-start h-full order-1 md:order-2">
        {/* Logo in the Top-Right Corner */}
        <div
          ref={logoRef}
          className={`flex justify-end md:pr-24 pr-4 transform transition-transform duration-700 ${
            logoInView ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
          }`}
        >
          <img
            src="/sanad-logo-white-rgb.svg"
            alt="Logo"
            className="w-40 h-32 md:w-48 md:h-40"
          />
        </div>

        {/* Text Content and Button */}
        <div
          ref={textRef}
          className={`w-full md:w-3/4 w-4/5 text-right md:text-right px-2 md:px-12 py-10 transform transition-transform duration-700 ${
            textInView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
          }`}
        >
          <p className="text-2xl md:text-xl pr-10 md:pr-2 mb-2 font-sans">كل ما عليك</p>
          <p className="text-5xl md:text-4xl pr-10 md:pr-2 font-sans">أرسل الفاتورة</p>
          <p className="text-xl md:text-xl pt-4 pr-10 md:pr-2 font-sans">
            وسع أعمالك مع أقوى مُحاسِب ذكي على الإطلاق
          </p>

          {/* Button Animation */}
          <button
            dir="rtl"
            onClick={() =>
              window.open(
                "https://wa.me/966544740441",
                "_blank",
                "noopener noreferrer"
              )
            }
            className={`font-sans my-10 md:mt-6 px-6 mr-10 md:mr-4 py-3 bg-white hover:bg-slate-100 text-indigo-500 font-bold text-xl rounded-lg shadow-lg transform transition-transform duration-700 ${
              buttonInView ? "scale-100 opacity-100" : "scale-75 opacity-0"
            }`}
            ref={buttonRef}
          >
            انضم معنا الآن!
          </button>
        </div>
      </div>

      {/* Left Side: Lazy Loaded Image */}
      <div
        ref={imageRef}
        className={`w-full md:w-2/5 md:ml-40 sm:ml-0 overflow-x-hidden flex justify-end h-auto order-2 md:order-1 transform transition-transform duration-700 ${
          imageInView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
        }`}
      >
        {!loaded && (
          <div className="w-full h-full sm:h-auto bg-gray-200 animate-pulse"></div>
        )}
        {imageInView && (
          <img
            src="/pro-hero.jpg"
            alt="User working on laptop"
            className={`w-full h-full sm:h-auto sm:object-cover transition-opacity duration-500 ${
              loaded ? "opacity-100" : "opacity-0"
            }`}
            onLoad={() => setLoaded(true)}
          />
        )}
      </div>
    </section>
  );
};

export default PromoSection;
