import React, { useState } from "react";

const PricPlans = () => {
    const [isMonthly, setIsMonthly] = useState(true); // State to toggle between monthly and yearly

    // Checkmark or X Mark component
    const Checkmark = ({ isCheck, bgColor }) => {
        // Determine the appropriate color for the checkmark based on background color
        const checkmarkColor = bgColor === 'indigo-500' ? 'text-white' : 'text-indigo-500';
        const xMarkColor = bgColor === 'indigo-500' ? 'text-white' : 'text-red-600'; // Adjusting the X mark color as well

        return (
            isCheck ? (
                // Checkmark icon (Right mark)
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-5 w-5 inline-block mr-2 ${checkmarkColor}`} // Dynamically setting color based on background
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12l5 5L20 7" />
                </svg>
            ) : (
                // X mark icon
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-5 w-5 inline-block mr-2 ${xMarkColor}`} // Dynamically setting color based on background
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            )
        );
    };


    return (
        <div className="pricing-section pb-10  bg-gray-50 font-sans" dir="rtl">
            {/* Title */}
            <div className="text-center mb-8">
                <h1 className="text-indigo-500 font-bold text-2xl md:text-3xl">
                    إبدأ اليوم مع باقات تناسب  نموك
                </h1>
                <p className="text-lg md:text-xl mt-4 text-gray-700">
                    إبدأ وتوسع في تجارتك ، مع دعم كامل
                </p>
            </div>

            {/* Pricing Toggle (Monthly / Yearly) */}
            <div className="flex justify-center items-center mb-10">
                <div className="relative rounded-full overflow-hidden">
                    <button
                        className={`py-2 px-4 rounded-r-full font-semibold ${isMonthly ? 'bg-white text-gray-600' : 'bg-gray-100 text-gray-400'}`}
                        onClick={() => setIsMonthly(true)}
                    >
                        شهري
                    </button>
                </div>
                <div className="relative rounded-full overflow-hidden">
                    <button
                        className={`py-2 px-4 rounded-l-full font-semibold ${!isMonthly ? 'bg-white text-gray-600' : 'bg-gray-100 text-gray-400'}`}
                        onClick={() => setIsMonthly(false)}
                    >
                        سنوي <span className="text-indigo-500">وفّر 15%</span>
                    </button>
                </div>
            </div>

            {/* Pricing Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-5xl mx-auto px-4 md:px-0">
                {isMonthly ? (
                    <>
                        {/* Monthly Pricing Cards */}
                        {/* Card 1: لايت */}
                        <div className="border shadow-md rounded-lg p-6 bg-white flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-2 text-gray-800">باقة المحاسب الذكي</h2>
                                <p className="text-3xl font-bold mb-1 text-indigo-500">
                                    350 ريال <span className="text-lg">/ شهريًا</span>
                                </p>

                                <ul className="space-y-2 text-gray-600 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={false} />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={false} />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={false} />استشارات مالية </li>
                                    <li><Checkmark isCheck={false} />دارة الرواتب </li>
                                    <li><Checkmark isCheck={false} />إدارة المخزون </li>
                                    <li><Checkmark isCheck={false} />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={false} />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={false} />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={false} />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={false} />النمذجة المالية </li>
                                    <li><Checkmark isCheck={false} />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-indigo-500 text-xl">6 أشهر</span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-indigo-500 text-xl">حتى 20 عملية  </span></li>



                                </ul>
                            </div>
                            <button
                                className="bg-indigo-500 text-white py-2 px-6 rounded-full hover:bg-indigo-100 hover:text-black transition"
                                onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
                            >
                                ابدأ تجارتك
                            </button>

                        </div>

                        {/* Card 2: النمو */}
                        <div className="relative bg-indigo-500 text-white rounded-lg p-6 shadow-lg flex flex-col justify-between">
                            <span className="absolute top-4 left-4 bg-white text-indigo-500 px-4 py-1 rounded-full font-semibold">
                                الأكثر طلبًا
                            </span>
                            <div>
                                <h2 className="text-xl font-semibold mb-2">الباقة الأساسية</h2>
                                <p className="text-3xl font-bold mb-1">1650 ريال <span className="text-lg">/ شهريًا</span></p>
                                <ul className="space-y-2 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />استشارات مالية </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />دارة الرواتب </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />إدارة المخزون </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={false} bgColor="white" />النمذجة المالية </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-white text-xl"> شهري </span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-white text-xl">حتى 60 عملية  </span></li>



                                </ul>
                            </div>
                            <button className="bg-white text-indigo-500 py-2 px-6 rounded-full hover:bg-indigo-100  transition"
                                onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
                            >

                                ابدأ تجارتك
                            </button>
                        </div>

                        {/* Card 3: الإحترافية */}
                        <div className="border shadow-md rounded-lg p-6 bg-white flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-2 text-gray-800">الباقة المتوسطة</h2>
                                <p className="text-3xl font-bold mb-1 text-indigo-500">1950 ريال <span className="text-lg">/ شهريًا</span></p>
                                <ul className="space-y-2 text-gray-600 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={true} />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={true} />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={true} />استشارات مالية </li>
                                    <li><Checkmark isCheck={true} />دارة الرواتب </li>
                                    <li><Checkmark isCheck={true} />إدارة المخزون </li>
                                    <li><Checkmark isCheck={true} />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={true} />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={true} />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={true} />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={true} />النمذجة المالية </li>
                                    <li><Checkmark isCheck={true} />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-indigo-500 text-xl"> شهري</span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-indigo-500 text-xl">من 61 حتى 100 عملية     </span></li>


                                </ul>
                            </div>
                            <button
                                onClick={() => window.open('https://wa.me/966544740441', '_blank', 'noopener noreferrer')}
                                className="bg-indigo-500 text-white py-2 px-6 rounded-full hover:bg-indigo-100 hover:text-black transition"
                            >
                                تواصل معنا
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        {/* Yearly Pricing Cards */}
                        {/* Card 1: لايت */}
                        <div className="border shadow-md rounded-lg p-6 bg-white flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-2 text-gray-800">باقة المحاسب الذكي</h2>
                                <p className="text-3xl font-bold mb-1 text-indigo-500">
                                    3,570 ريال <span className="text-lg">/  سنويًا</span>
                                </p>

                                <ul className="space-y-2 text-gray-600 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={false} />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={false} />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={false} />استشارات مالية </li>
                                    <li><Checkmark isCheck={false} />دارة الرواتب </li>
                                    <li><Checkmark isCheck={false} />إدارة المخزون </li>
                                    <li><Checkmark isCheck={false} />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={false} />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={false} />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={false} />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={false} />النمذجة المالية </li>
                                    <li><Checkmark isCheck={false} />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-indigo-500 text-xl">6 أشهر</span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-indigo-500 text-xl">حتى 20 عملية  </span></li>



                                </ul>
                            </div>
                            <button className="bg-indigo-500 text-white py-2 px-6 rounded-full hover:bg-indigo-100 hover:text-black transition"
                                onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
                            >
                                ابدأ تجارتك
                            </button>
                        </div>

                        {/* Card 2: النمو */}
                        <div className="relative bg-indigo-500 text-white rounded-lg p-6 shadow-lg flex flex-col justify-between">
                            <span className="absolute top-4 left-4 bg-white text-indigo-500 px-4 py-1 rounded-full font-semibold">
                                الأكثر طلبًا
                            </span>
                            <div>
                                <h2 className="text-xl font-semibold mb-2">الباقة الأساسية</h2>
                                <p className="text-3xl font-bold mb-1">16,830 ريال <span className="text-lg">/ سنويًا</span></p>
                                <ul className="space-y-2 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />استشارات مالية </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />دارة الرواتب </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />إدارة المخزون </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={true} bgColor="indigo-500" />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={false} bgColor="white" />النمذجة المالية </li>
                                    <li><Checkmark isCheck={false} bgColor="white" />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-white text-xl"> شهري </span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-white text-xl">حتى 20 عملية  </span></li>



                                </ul>
                            </div>
                            <button className="bg-white text-indigo-500 py-2 px-6 rounded-full hover:bg-indigo-100 transition " 
                            onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
                            >
                                ابدأ تجارتك
                            </button>
                        </div>

                        {/* Card 3: الإحترافية */}
                        <div className="border shadow-md rounded-lg p-6 bg-white flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-2 text-gray-800">الباقة المتوسطة</h2>
                                <p className="text-3xl font-bold mb-1 text-indigo-500">19,890 ريال <span className="text-lg">/ سنويًا</span></p>
                                <ul className="space-y-2 text-gray-600 mb-6 mt-5">
                                    <li><Checkmark isCheck={true} />تسجيل البيانات </li>
                                    <li><Checkmark isCheck={true} />تقرير الربح والخسارة </li>
                                    <li><Checkmark isCheck={true} />تحليل البيانات الماليه بالذكاء الاصطناعي </li>
                                    <li><Checkmark isCheck={true} />ميزان المراجعة والقوائم المالية </li>
                                    <li><Checkmark isCheck={true} />الربط مع فريق محاسبي محترف </li>
                                    <li><Checkmark isCheck={true} />استشارات مالية </li>
                                    <li><Checkmark isCheck={true} />دارة الرواتب </li>
                                    <li><Checkmark isCheck={true} />إدارة المخزون </li>
                                    <li><Checkmark isCheck={true} />مناقشة الوضع المالي </li>
                                    <li><Checkmark isCheck={true} />تقييم الشجرة المحاسبية </li>
                                    <li><Checkmark isCheck={true} />خدمة مخصصة </li>
                                    <li><Checkmark isCheck={true} />التحليل المالي(KPI)</li>
                                    <li><Checkmark isCheck={true} />النمذجة المالية </li>
                                    <li><Checkmark isCheck={true} />مراقبة فرق العمل المحاسبية </li>
                                    <li className="mr-4" >مدة العقد  <span className="text-indigo-500 text-xl"> شهري</span></li>
                                    <li className="mr-4">عمليات الإدخال <span className="text-indigo-500 text-xl">من 61 حتى 100 عملية     </span></li>


                                </ul>
                            </div>
                            <button
                                onClick={() => window.open('https://wa.me/966544740441', '_blank', 'noopener noreferrer')}
                                className="bg-indigo-500 text-white py-2 px-6 rounded-full hover:bg-indigo-100 hover:text-black transition"
                            >
                                تواصل معنا
                            </button>


                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PricPlans;
