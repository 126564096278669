import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleCloseMenu = () => {
        setIsOpen(false);
    };
    return (
        <nav className="bg-white  z-50 shadow-sm  fixed w-full top-0">
            <div className="container mx-auto px-4 py-4 md:py-6 lg:py-8 flex items-center justify-between md:justify-center">
                {/* Logo */}
                <Link to="/" className="md:ml-10 md:order-2 md:flex md:justify-center">
                    <img src="/logo.svg" alt="Your Logo" className="h-8" /> {/* Adjust the height as needed */}
                </Link>

                {/* Mobile Menu Button */}
                <button
                    onClick={toggleMenu}
                    className="md:hidden focus:outline-none order-1 md:order-3"
                >
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'} />
                    </svg>
                </button>

                {/* Desktop Menu */}
                <ul className="hidden md:flex justify-center space-x-4 md:space-x-6 lg:space-x-8 order-3 md:order-1">
                <li><Link to="/landing" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900"> سحابه سند </Link></li>
                    <li><Link to="/faq" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">المقالات </Link></li>
                    <li><Link to="/jobs" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">الوظائف</Link></li>
                    <li><Link to="/clients" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">العملاء </Link></li>
                    <li><Link to="/packages" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">الباقات</Link></li>
                    {/* <li><Link to="/goals" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">كيف نعمل</Link></li> */}
                    <li><Link to="/works" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">كيف نعمل</Link></li>

                    <li><Link to="/whyUs" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">لماذا نحن  </Link></li>
                    <li><Link to="/main" className="text-gray-950 text-sm font-bold hover:border-b-2 border-gray-900">الرئيسيه</Link></li>
                </ul>

                {/* Contact Us Button */}
                <a 
                    href="https://wa.me/966544740441"  // Change here to open WhatsApp
                    className="hidden md:block sm:block bg-amber-300 text-black font-bold mr-10 md:py-1 md:px-4 p-1 lg:py-2 lg:px-8 rounded-lg hover:text-white"
                    target="_blank" // Optional: opens in a new tab
                    rel="noopener noreferrer" // Security best practice
                >
                    تواصل معنا
                </a>
            </div>

            {/* Mobile Menu Dropdown */}
            <div className={`md:hidden absolute w-full ${isOpen ? 'block' : 'hidden'} top-full left-0 bg-emerald-600 rounded-md p-4 z-20`}>
                <ul className="space-y-4">
                    <li><Link to="/main" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">الرئيسيه</Link></li>
                    <li><Link to="/whyUs" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">لماذا نحن</Link></li>
                    {/* <li><Link to="/goals" className="hover:border-b-4 border-indigo-950 inline-block">كيف نعمل</Link></li> */}
                    <li><Link to="/works" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">كيف نعمل</Link></li>
                    <li><Link to="/packages" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">الباقات</Link></li>
                    <li><Link to="/clients" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">العملاء </Link></li>
                    <li><Link to="/jobs" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">الوظائف</Link></li>
                    <li><Link to="/faq" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">المقالات </Link></li>
                    <li><Link to="/landing" onClick={handleCloseMenu} className="hover:border-b-4 border-indigo-950 inline-block">سحابه سند </Link></li>


                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
