import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const MenuCost = () => {
  // Refs for the columns and comparison section
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);
  const comparisonRef = useRef(null);

  // Check if each element is in view
  const isLeftColumnInView = useInView(leftColumnRef, { threshold: 0.1 });
  const isRightColumnInView = useInView(rightColumnRef, { threshold: 0.1 });
  const isComparisonInView = useInView(comparisonRef, { threshold: 0.1 });

  // Animation variants
  const columnVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.0 } }, // Increased to 1 second
  };

  const comparisonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.0 } }, // Increased to 1 second
  };

  return (
    <div className="w-full bg-gray-50 text-center py-24 font-sans">
      {/* Title */}
      <h2 className="text-3xl font-bold text-black">
        تكلفة <span className="text-indigo-500">المحاسب الذكي </span> أقل
      </h2>

      {/* Two Columns Section */}
      <div className="mt-8 flex items-center justify-center  ">
        {/* Left Column */}
        <motion.div
          ref={leftColumnRef}
          className=" text-gray-800 py-2 px-4 bg-slate-100 rounded-lg flex flex-col items-center justify-center flex-grow max-w-[25%] mx-2"
          initial="hidden"
          animate={isLeftColumnInView ? 'visible' : 'hidden'}
          variants={columnVariants}
        >
          <h3 className="font-bold text-lg ">المحاسب الذكي </h3>
        </motion.div>

        {/* SVG Image Instead of VS Circle */}
        <div className="relative mx-10 flex items-center justify-center">
          <img 
            src="/vs-landing.png" // Replace with your actual SVG path
            alt="VS"
            className="w-12 h-12" // Adjust size as needed
          />
        </div>

        {/* Right Column */}
        <motion.div
          ref={rightColumnRef}
          className="bg-indigo-500 text-gray-800  py-2 px-4 rounded-lg flex flex-col items-center justify-center flex-grow max-w-[25%] mx-2"
          initial="hidden"
          animate={isRightColumnInView ? 'visible' : 'hidden'}
          variants={columnVariants}
        >
          <h3 className="font-bold text-lg">المحاسبة التقليدية </h3>
        </motion.div>
      </div>

      {/* Comparison Section */}
      <motion.div
        ref={comparisonRef}
        className="mt-8 flex flex-col w-full max-w-4xl mx-auto rounded-lg overflow-hidden shadow-md"
        initial="hidden"
        animate={isComparisonInView ? 'visible' : 'hidden'}
        variants={comparisonVariants}
      >
        {/* Comparison Rows */}
        {[
          { left: 'أتمتة كاملة بتكلفة منخفضه', right: 'تكلفة توظيف فريق محاسبي داخلي' },
          { left: 'دقة عالية في الإدخالات باستخدام الذكاء الاصطناعى', right: 'احتمالية أخطاء بشرية مرتفعة' },
          { left: 'لا حاجة لمتابعة المحاسبين', right: 'الحاجة لمتابعة مستمرة للفريق' },
          { left: 'OCR معالجة فورية للفواتير باستخدام', right: 'بطء في معالجة الفواتير' },
          { left: 'تقارير تلقائية محدثة ودقيقة', right: 'تقارير يدوية وغير محدثه' },
          { left: 'دعم فني متواصل وبرامج تدريب متاحه', right: 'تكلفة مرتفعة للتدريب والتطوير' },
          { left: 'أرشفة إلكترونية سهلة وآمنه', right: 'أرشفة ورقية معقدة ' },
          { left: 'أتمتة بدون تدخل بشري', right: 'اعتماد كبير على التدخل البشري ' },
          { left: 'تكاليف ثابتة وشفافة تبدأ من 350 ريال شهريًا', right: 'تكاليف إضافية غير متوقعة ' },

        ].map((item, index) => (
          <div key={index} className="flex">
            <motion.div
              className="w-1/2 bg-slate-100  text-black p-4"
              initial="hidden"
              animate={isComparisonInView ? 'visible' : 'hidden'}
              variants={comparisonVariants}
            >
              {item.left}
            </motion.div>
            <motion.div
              className="w-1/2 bg-indigo-500 text-white p-4"
              initial="hidden"
              animate={isComparisonInView ? 'visible' : 'hidden'}
              variants={comparisonVariants}
            >
              {item.right}
            </motion.div>
          </div>
        ))}
      </motion.div>
      <h5 dir='rtl' className="text-2xl  text-black mt-5">
      ابدأ الآن بتقليل تكاليف المحاسبه واجعل الاداره الماليه أكثر سهوله ودقة      
      ،   <span className="text-indigo-500"> واستخدام المحاسب الذكي </span>  .    

       </h5>
    </div>
  );
};

export default MenuCost;
