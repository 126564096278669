import React from "react";
import { useInView } from "react-intersection-observer";

function ProfessionalPackage() {
  // Hooks for each section
  const { ref: imageRef, inView: imageInView } = useInView({ threshold: 0.1 });
  const { ref: headingRef, inView: headingInView } = useInView({ threshold: 0.1 });
  const { ref: textRef, inView: textInView } = useInView({ threshold: 0.1 });

  return (
    <div className="flex flex-col overflow-x-hidden md:flex-row items-center bg-slate-100 p-2 rounded-lg shadow-lg">
      {/* Left Side: Image */}
      <div
        ref={imageRef}
        className={`relative w-full md:w-1/3 ml-20 flex justify-center md:justify-start order-2 md:order-1 transform transition-transform duration-700 ${
          imageInView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
        }`}
      >
        {/* <img
          src="/path-to-your-image.jpg" // Replace with your image path
          alt="Professional Package"
          className="w-2/3 md:w-full object-cover"
        /> */}
      </div>

      {/* Right Side: Text Content */}
      <div
        ref={headingRef}
        className={`w-full md:w-1/2 text-right pt-4 md:mt-0 md:pl-20 order-1 md:order-2 transform transition-transform duration-700 ${
          headingInView ? "translate-x-0 opacity-100" : "translate-x-10 opacity-0"
        }`}
      >
        <h2 className="text-3xl font-bold mb-4 text-gray-800 font-sans">
          لماذا <span className="text-indigo-500">مُحاسِب سند الذكي</span>؟
        </h2>
        <p
          ref={textRef}
          dir="rtl"
          className={`text-gray-900 text-2xl mb-12 pt-4 font-sans transform transition-transform duration-700 ${
            textInView ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
          }`}
        >
          مع مُحاسِب سند الذكي،<span className="text-indigo-500"> لن تحتاج إلى فريق محاسبي</span>.
          نقدم لك حلاً متكاملاً يعتمد على الذكاء الاصطناعي، مما يقلل من تكاليف الإدارة.
          <br />
          <br />
          نقوم بأتمتة جميع العمليات المحاسبية، من قراءة الفواتير إلى إدخال البيانات بدقة، دون تدخل بشري. وإذا احتجت، يمكننا
          ربطك بمحاسب محترف لمتابعة سير العمل.
        </p>
      </div>
    </div>
  );
}

export default ProfessionalPackage;
