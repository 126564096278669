import React from 'react';
import { motion } from 'framer-motion';

const FeatureSection = () => {
  return (
    <div className="flex overfow-x-hidden flex-col overflow-x-hidden items-center bg-gray-100 py-20 px-4 sm:px-8" dir="rtl">
      {/* Title */}
      <h2 className="text-3xl sm:text-4xl font-bold mb-20 text-center font-sans">
        كل ما تحتاجه لتحقيق أهدافك التجارية
      </h2>

      {/* Cards Container */}
      <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
        
        {/* Card 1 */}
        <motion.div
          className="bg-white rounded-xl shadow-lg p-4 sm:p-4 text-center w-64 sm:w-80 relative mb-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: false, amount: 0.2 }}
        >
          <img
            src="/profit.png"
            alt="feature1"
            className="w-20 h-20 md:w-32 md:h-32 sm:w-28 sm:h-28 absolute md:-top-16 -top-10 sm:-top-16 left-1/2 transform -translate-x-1/2"
          />
          <h3 className="text-md sm:text-lg font-bold mb-2 mt-12 sm:mt-14">تحقيق نمو الأرباح</h3>
          <p className="text-gray-600 text-lg sm:text-lg pb-8 sm:pb-10">
            الأتمتة الشاملة تساعدك في تعزيز الربحية عبر تقليل التكاليف.
          </p>
        </motion.div>

        {/* Card 2 */}
        <motion.div
          className="bg-white rounded-xl shadow-lg p-4 sm:p-4 text-center w-64 sm:w-80 relative mb-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: false, amount: 0.2 }}
        >
          <img
            src="/operation_effiency.png"
            alt="feature3"
            className="w-20 h-20 md:w-32 md:h-32 sm:w-28 sm:h-28 absolute md:-top-16 -top-10 sm:-top-16 left-1/2 transform -translate-x-1/2"
          />
          <h3 className="text-md sm:text-lg font-bold mb-2 mt-12 sm:mt-14">زيادة كفاءة العمليات</h3>
          <p className="text-gray-600 text-lg sm:text-lg pb-8 sm:pb-10">
            نظام محاسبي ذكي يحسّن الأداء، ويوفر الوقت بتقليل المهام اليدوية.
          </p>
        </motion.div>

        {/* Card 3 */}
        <motion.div
          className="bg-white rounded-xl shadow-lg p-4 sm:p-4 text-center w-64 sm:w-80 relative mb-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: false, amount: 0.2 }}
        >
          <img
            src="/accounting_mistakes.png"
            alt="feature2"
            className="w-20 h-20 md:w-32 md:h-32 sm:w-28 sm:h-28 absolute -top-10 sm:-top-16 left-1/2 transform -translate-x-1/2"
          />
          <h3 className="text-md sm:text-lg font-bold mb-2 mt-12 sm:mt-14">تقليل الأخطاء المحاسبية</h3>
          <p className="text-gray-600 text-lg sm:text-lg pb-8 sm:pb-10">
            الأتمتة الذكية تقلل الأخطاء وتضمن دقة البيانات المحاسبية.
          </p>
        </motion.div>
      </div>

      <div className="flex flex-wrap justify-center gap-4 sm:gap-8 pt-14">
        
        {/* Card 4 */}
        <motion.div
          className="bg-white rounded-xl shadow-lg p-4 sm:p-4 text-center w-64 sm:w-80 relative mb-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: false, amount: 0.2 }}
        >
          <img
            src="/save_cost.png"
            alt="feature6"
            className="w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 absolute md:-top-16 -top-10 sm:-top-14 left-1/2 transform -translate-x-1/2"
          />
          <h3 className="text-md sm:text-lg font-bold mb-2 mt-12 sm:mt-14">توفير تكاليف المحاسبة</h3>
          <p className="text-gray-600 text-lg sm:text-lg pb-8 sm:pb-10">
            تخلص من تكاليف توظيف فريق محاسبي داخلي باعتماد الأتمتة الذكية.
          </p>
        </motion.div>

        {/* Card 5 */}
        <motion.div
          className="bg-white rounded-xl shadow-lg p-4 sm:p-4 text-center w-64 sm:w-80 relative mb-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: false, amount: 0.2 }}
        >
          <img
            src="/finance_report.png"
            alt="feature5"
            className="w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 absolute md:-top-16 -top-10 sm:-top-14 left-1/2 transform -translate-x-1/2"
          />
          <h3 className="text-md sm:text-lg font-bold mb-2 mt-12 sm:mt-14">لا حاجة لمتابعة المحاسبين</h3>
          <p className="text-gray-600 text-lg sm:text-lg pb-8 sm:pb-10">
            مع المحاسب الذكي، الإدخالات دقيقة تلقائيًا، مما يغنيك عن متابعة المحاسبين لضمان صحة البيانات.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default FeatureSection;
