import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const WaveDivider = () => {
    const videoRef = useRef(null);

    // Create refs for each element
    const refVideo = useRef(null);
    const refTitle = useRef(null);
    const refText = useRef(null);
    const refButton = useRef(null);

    // Update useInView options to check for visibility when fully in the viewport
    const isInViewVideo = useInView(refVideo, { threshold: 0.5 });
    const isInViewTitle = useInView(refTitle, { threshold: 0.5 });
    const isInViewText = useInView(refText, { threshold: 0.5 });
    const isInViewButton = useInView(refButton, { threshold: 0.5 });
    return (
        <div className="relative bg-white mt-10 pt-32 pb-10 ">
            {/* SEO Metadata */}
            {/* <Helmet>
                <title>الرئيسيه</title>
                <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
            </Helmet> */}
            {/* Content Section */}
            <section className="">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 overflow-hidden md:grid-cols-2 gap-8">
                        {/* First Column - Video Section */}
                        <motion.div
                            ref={refVideo}
                            initial={{ opacity: 0, x: 100 }} // Start from the right
                            animate={{ opacity: isInViewVideo ? 1 : 0, x: isInViewVideo ? 0 : 100 }} // Animate to visible
                            transition={{ duration: 0.5 }} // Animation duration
                            className="px-2 flex flex-col justify-center items-center order-2 md:order-1"
                        >
                            <div className="relative w-full mb-4 ">
                                <video
                                    ref={videoRef}
                                    className="w-3/4 h-auto mx-auto"
                                    src="/sanad.mp4" // Replace with your video URL
                                    controls
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </motion.div>

                        {/* Second Column - Text Section */}
                        <div className="px-4 flex flex-col justify-start items-end order-1 md:order-2 w-full md:w-5/4 lg:w-2/3">
                            <motion.h2
                                ref={refTitle}
                                initial={{ opacity: 0, x: 100 }} // Start from the right
                                animate={{ opacity: isInViewTitle ? 1 : 0, x: isInViewTitle ? 0 : 100 }} // Animate to visible
                                transition={{ duration: 0.5 }} // Animation duration
                                className="text-xl sm:text-2xl md:text-3xl lg:text-3xl font-bold text-right mb-10"
                            >
                                !<span className="text-emerald-600">كل ما عليك: </span> أرسل الفاتورة
                            </motion.h2>

                            <motion.p
                                ref={refText}
                                initial={{ opacity: 0, y: 100 }} // Start from the bottom
                                animate={{ opacity: isInViewText ? 1 : 0, y: isInViewText ? 0 : 100 }} // Animate to visible
                                transition={{ duration: 0.5 }} // Animation duration
                                dir="rtl"
                                className="text-gray-600 font-bold mb-12 w-full text-right text-lg"
                            >
                                مع <span className="text-emerald-600 font-bold text-xl">محاسب سند الذكي</span>، يصبح دورك في غاية البساطة. كل ما عليك فعله هو إرسال الفاتورة عبر إحدى الطرق المتاحة مثل التحميل المباشر، البريد الإلكتروني، أو WhatsApp.
                            </motion.p>

                            {/* Contact Us Button styled like the paragraph with border radius */}
                            <motion.a
                                ref={refButton}
                                initial={{ opacity: 0, x: -100, scale: 0 }} // Start from the bottom
                                animate={{ opacity: isInViewButton ? 1 : 0, x: isInViewButton ? 0 : -100, scale: isInViewButton ? 1 : 0 }} // Animate to visible
                                transition={{ duration: 0.5 }} // Animation duration
                                className="bg-emerald-600 text-white py-2 mb-10 px-4 rounded mt-4 hover:bg-emerald-500 hover:text-black cursor-pointer" // Added rounded class for border radius
                                href="https://wa.me/966544740441" target='_blank' >
                                تواصل معنا
                            </motion.a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Custom Wave Divider */}
            <div className="absolute bottom-0 left-0 w-full overflow-hidden">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    className="w-full h-12"
                >
                    <path
                        d="M0,60 C300,0 900,120 1200,60 L1200,120 L0,120 Z"
                        className="fill-emerald-600"
                    />
                </svg>
            </div>
        </div>
    );
};

export default WaveDivider;
